import {inject, LogManager} from "aurelia-framework";
import {NavigationInstruction, Next} from "aurelia-router";
import {User, UserType} from "../models/user";

export let HOME_ROUTE_NAME = "home";
export let LOGIN_ROUTE_NAME = "login";
export let CLINIC_ANALYSES_ROUTE_NAME = "clinic-analyses";
export let CLINIC_UPLOAD_ROUTE_NAME = "clinic-upload";
export let CLINIC_ACCOUNT_ROUTE_NAME = "clinic-account";
export let PATIENT_ANALYSIS_ROUTE_NAME = "patient-analysis";


let logger = LogManager.getLogger("logger");

@inject(User)
export class NavbarTabPrerenderStep {
  user: User;

  constructor(user: User) {
    logger.debug("NavbarTabPrerenderStep.constructor:", user);
    this.user = user;
  }

  run(instruction: NavigationInstruction, next: Next) {
    logger.debug("NavbarTabPrerenderStep.run:", this.user.loginType, this.user.loginName);
    instruction.router.routes.forEach((route) => {
      switch (route.name) {
        case HOME_ROUTE_NAME:
          route.settings.displayPage = false;
          break;

        case LOGIN_ROUTE_NAME:
          route.settings.displayPage = (route.name === instruction.config.name);
          break;

        case CLINIC_ANALYSES_ROUTE_NAME:
          route.settings.displayPage = (this.user.loginType === UserType.CLINIC_USER);
          break;

        case CLINIC_UPLOAD_ROUTE_NAME:
          route.settings.displayPage = ((this.user.loginType === UserType.CLINIC_USER) && !this.user.isReadOnly);
          break;

        case CLINIC_ACCOUNT_ROUTE_NAME:
          route.settings.displayPage = (this.user.loginType === UserType.CLINIC_USER);
          break;

        case PATIENT_ANALYSIS_ROUTE_NAME:
          route.settings.displayPage = ((this.user.loginType === UserType.CLINIC_USER) && (route.name === instruction.config.name));
          break;

        default:
          route.settings.displayPage = true;
          logger.debug("NavbarTabPrerenderStep.run: default match");
          break;
      }
      logger.debug("NavbarTabPrerenderStep.run:", route.route, route.name, route.settings.displayPage);
    });
    return next();
  }
}
