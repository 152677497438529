import {DialogService} from "aurelia-dialog";
import {EventAggregator} from "aurelia-event-aggregator";
import {inject, LogManager, PLATFORM} from "aurelia-framework";
import {Router, RouterConfiguration, RouteConfig} from "aurelia-router";
import {UserType} from "./models/user";
import "whatwg-fetch";
import {AuthenticatedStep} from "./common/authenticated-step";
import {MessageDialog, MessageDialogType} from "./common/message-dialog";
// import {CLINIC_ACCOUNT_ROUTE_NAME, CLINIC_ANALYSES_ROUTE_NAME, CLINIC_UPLOAD_ROUTE_NAME, HOME_ROUTE_NAME, LOGIN_ROUTE_NAME,
//         NavbarTabPrerenderStep, PATIENT_ANALYSIS_ROUTE_NAME, STAFF_ACCOUNT_CREATE_CLINIC_NAME, STAFF_ACCOUNT_CREATE_CLINIC_ROUTE,
//         STAFF_ACCOUNT_CREATE_USER_NAME, STAFF_ACCOUNT_CREATE_USER_ROUTE, STAFF_ACCOUNT_MAINTENANCE_NAME, STAFF_ACCOUNT_MAINTENANCE_ROUTE,
//         STAFF_ACCOUNT_MODIFY_CLINIC_NAME, STAFF_ACCOUNT_MODIFY_CLINIC_ROUTE, STAFF_ACCOUNT_MODIFY_USER_NAME, STAFF_ACCOUNT_MODIFY_USER_ROUTE} from "./common/navbar-tab-prerender-step";
import {CLINIC_ACCOUNT_ROUTE_NAME, CLINIC_ANALYSES_ROUTE_NAME, CLINIC_UPLOAD_ROUTE_NAME, HOME_ROUTE_NAME, LOGIN_ROUTE_NAME,
          NavbarTabPrerenderStep, PATIENT_ANALYSIS_ROUTE_NAME} from "./common/navbar-tab-prerender-step";
import environment from "../config/environment.json";
import {User} from "./models/user";


let logger = LogManager.getLogger("logger");

@inject(User, EventAggregator, DialogService)
export class App {
  private user: User;
  private router: Router;
  private eventAggregator: EventAggregator;
  private dialogService: DialogService;
  private DEFAULT_USER = UserType.DEFAULT_USER;
  private intervalHandler: any;
  isResearchUrl: boolean;


  constructor(user: User, eventAggregator: EventAggregator, dialogService: DialogService) {
    this.user = user;
    this.eventAggregator = eventAggregator;
    this.dialogService = dialogService;
    logger.debug("App.constructor:", this, this.user, this.DEFAULT_USER);
    this.isResearchUrl = RegExp(environment.researchUrlRegex).test(window.location.hostname);

    this.eventAggregator.subscribe(environment.refreshTimerChannel, (start: boolean) => {
      logger.debug("App.eventAggregator:", environment.refreshTimerChannel, start);
      if (start) {
        this.intervalHandler = setInterval(() => {
          logger.debug("App.interval:");
          this.eventAggregator.publish(environment.refreshViewChannel, true);
        }, environment.refreshViewDelay);
      } else {
        clearInterval(this.intervalHandler);
      }
    });
  }


  // @ts-ignore
  async activate(params: any, routeConfig: RouteConfig) {
    let result: boolean;

    logger.debug("App.activate1:");
    result = await this.user.isAuthenticated();
    if (result) {
      logger.debug("App.activate2: restarting refresh timer");
      this.eventAggregator.publish(environment.refreshTimerChannel, true);
    }
  }


  configureRouter(config: RouterConfiguration, router: Router) {
    logger.debug("App.configureRouter:", this, this.user, HOME_ROUTE_NAME);
    this.router = router;
    config.title = "Synergyne";
    config.addPipelineStep("authorize", AuthenticatedStep);
    config.addPipelineStep("preRender", NavbarTabPrerenderStep);
    config.mapUnknownRoutes({route: "unknown", redirect: "#/"});
    // Note: A literal string must be used with PLATFORM.moduleName
    config.map([
      {
        route: ["", "home"],
        name: HOME_ROUTE_NAME,
        moduleId: PLATFORM.moduleName("home", "home"),
        nav: false,
        settings: {secured: true, displayPage: false}},
      {
        route: "login",
        name: LOGIN_ROUTE_NAME,
        moduleId: PLATFORM.moduleName("login", "login"),
        nav: true,
        title: "Login",
        settings: {secured: false, displayPage: false}},
      {
        route: "clinic/analyses",
        name: CLINIC_ANALYSES_ROUTE_NAME,
        moduleId: PLATFORM.moduleName("clinic-analyzes", "clinic-interface"),
        nav: true,
        title: "Analyses",
        settings: {secured: true, displayPage: false}},
      {
        route: "clinic/upload",
        name: CLINIC_UPLOAD_ROUTE_NAME,
        moduleId: PLATFORM.moduleName("clinic-upload", "clinic-interface"),
        nav: true,
        title: "New Upload",
        settings: {secured: true, displayPage: false}},
      {
        route: "clinic/account",
        name: CLINIC_ACCOUNT_ROUTE_NAME,
        moduleId: PLATFORM.moduleName("clinic-account", "clinic-interface"),
        nav: true,
        title: "Account",
        settings: {secured: true, displayPage: false}},
      {
        route: "clinic/analysis/:analysisId?",
        href: "#/clinic/analysis",
        name: PATIENT_ANALYSIS_ROUTE_NAME,
        moduleId: PLATFORM.moduleName("patient-analysis", "clinic-interface"),
        nav: true,
        title: "Patient Analysis",
        settings: {secured: true, displayPage: false}},
       ]);
  }


  async logout() {
    let response: boolean;

    logger.debug("App.logout1:", this.user, this.user.loginType);
    try {
      response = await this.user.logout();
      logger.debug("App.logout2:", response);
      await this.router.navigateToRoute("login");
    }
    catch (reason) {
      logger.debug("App.logout4:", reason.message);
      await this.dialogService.open({viewModel: MessageDialog,
        model: {
          type: MessageDialogType.error,
          twoButton: false,
          title: "Logout Failed",
          subTitle: "The logout failed with the message:",
          message: reason.message}});
    }
  }
}
