import {inject, LogManager} from "aurelia-framework";
import {NavigationInstruction, Next, Redirect} from "aurelia-router";
import {User, UserType} from "../models/user";
// import { STAFF_ACCOUNT_MAINTENANCE_ROUTE } from "./navbar-tab-prerender-step";
let logger = LogManager.getLogger("logger");

@inject(User)
export class AuthenticatedStep {
  user: User;


  constructor(user: User) {
    logger.debug("AuthenticatedStep.constructor:", user);
    this.user = user;
  }


  async run(instruction: NavigationInstruction, next: Next) {
    let authenticated: Boolean;

    logger.debug("AuthenticatedStep.run1:");
    let isRouteSecured = instruction.getAllInstructions().some((i) => i.config.settings.secured);
    logger.debug("AuthenticatedStep.run2:", isRouteSecured, instruction.config.name);
    if (isRouteSecured) {
      authenticated = await this.user.isAuthenticated();
      logger.debug("AuthenticatedStep.run3:", this.user.loginType, authenticated);
      if (authenticated) {
        if (instruction.config.name !== "home") {
          logger.debug("AuthenticatedStep.run4:");
          return next();
        }
        else {
          switch (this.user.loginType) {
            case UserType.CLINIC_USER:
              logger.debug("AuthenticatedStep.run5:");
              return next.cancel(new Redirect("clinic/analyses"));

            case UserType.STAFF_USER:
            case UserType.ANALYSIS_USER:
            case UserType.REVIEW_USER:
              return next.cancel(new Redirect(window.location.origin + '/synadmin'));

            case UserType.DEFAULT_USER:
              logger.debug("AuthenticatedStep.run7:");
              return next.cancel(new Redirect("login"));
          }
        }
      } else {
        logger.debug("AuthenticatedStep.run8: ", this.user.loginType, authenticated);
        return next.cancel(new Redirect("login"));
      }
    } else {
      logger.debug("AuthenticatedStep.run9:");
      return next();
    }
  }
}
