import {DialogController} from "aurelia-dialog";
import {inject} from "aurelia-framework";

export enum MessageDialogType {
  error,
  question,
  success,
  warning,
  information}


@inject(DialogController)
export class MessageDialog {
  controller: DialogController;
  title: string;
  subTitle: string;
  message: string[];
  twoButtons: boolean;
  okButtonString = "Ok";
  cancelButtonString = "Cancel";
  titleClass: string;

  constructor(controller: DialogController) {
    this.controller = controller;
    this.controller.settings.overlayDismiss = false;
    this.controller.settings.keyboard = ["Escape"];
    this.controller.settings.centerHorizontalOnly = true;
  }

  activate(value: {title: string, subTitle: string, message: string,
    twoButtons: boolean, okButtonString: string, cancelButtonString: string,
    type: MessageDialogType, overlayClickDismiss: boolean}) {
    this.title = value.title;
    this.subTitle = value.subTitle;
    this.message = value.message.split("\n");
    this.twoButtons = value.twoButtons;
    if (value.okButtonString) {
      this.okButtonString = value.okButtonString;
    }
    if (value.cancelButtonString) {
      this.cancelButtonString = value.cancelButtonString;
    }
    if (value.overlayClickDismiss) {
      this.controller.settings.overlayDismiss = true;
    }

    switch (value.type) {
      case MessageDialogType.error:
        this.titleClass = "fa fa-lg fa-minus-circle error-title";
        break;

      case MessageDialogType.question:
        this.titleClass = "fa fa-lg fa-question-circle question-title";
        break;

      case MessageDialogType.success:
        this.titleClass = "fa fa-lg fa-check-circle success-title";
        break;

      case MessageDialogType.warning:
        this.titleClass = "fa fa-lg fa-exclamation-circle warning-title";
        break;

      case MessageDialogType.information:
        this.titleClass = "fa fa-lg fa-info-circle information-title";
        break;

      default:
        this.titleClass = "fa fa-lg fa-info-circle information-title";
        break;
    }
  }

  click() {
    console.log("abcd");
  }
}
