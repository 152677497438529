export const INVALID_EMAIL_ADDRESS = "invalidEmail";

export const CycleTypeStrings = ["fresh", "frozen", "natural", "naturalfet"];
export const EmbryoTypeStrings = ["notReported", "patientEmbryo", "donorEmbryo", "donorOocyte"];
export const AcceptMimeTypesStrings = ["image/png", "image/jpeg", "image/tiff"];

export const AccountTokenName = "account-token";
export const AnalysisImagesTokenName = "analysisImages";
export const BillableTokenName = "billable";
export const ClinicCommentTokenName = "clinicComment";
export const ClinicIdTokenName = "clinicId";
export const CycleTypeTokenName = "cycleType";
export const EmbryoTypeTokenName = "embryoType"
export const DateOfBirthTokenName = "dateOfBirth";
export const DoctorTokenName = "doctor";
export const PatientIdTokenName = "patientId";

export const BadRequestStatusCode = 400;
export const UnauthorizedStatusCode = 401;
export const NotFoundStatusCode = 404;
export const InternalServerErrorStatusCode = 500;
export const InternalServerErrorStatusText = "InternalServerError";

export const AccountDisabledStatusText = "AccountDisabled";
export const ExtraAuthRequiredStatusText = "ExtraAuth";
export const MaximumFailedLoginAttemptsExceededStatusText = "MaxLogins";
export const UnauthorizedStatusText = "Unauthorized";
export const NotFoundStatusText = "NotFoundError";
export const InvalidUserPWStatusText = "InvalidUserPW";


export enum SortBy {
  SUBMIT_DATE,
  PATIENT_ID}

export class AnalysisObject {
  id: string;
  submitDate: string;
  patientId: string;
  dateOfBirth: string;
  doctor: string;
  reviewDate: string;
  status: string;
  matrisScore: number;
  blinded: boolean;
  clinicComment: string;
  reviewClinicComment: string;
  reviewComment: string;
  isBillable: boolean;
  imageCount: number;
}
