import {computedFrom} from "aurelia-binding";
import {HttpClient, HttpClientConfiguration} from "aurelia-fetch-client";
import {inject, LogManager, NewInstance} from "aurelia-framework";
import {Clinic} from "./clinic";
import {AccountTokenName, BadRequestStatusCode, ExtraAuthRequiredStatusText,
        MaximumFailedLoginAttemptsExceededStatusText, UnauthorizedStatusCode, UnauthorizedStatusText, INVALID_EMAIL_ADDRESS} from "../common/constants";
import environment from "../../config/environment.json";

let logger = LogManager.getLogger("logger");

export enum UserType {
  CLINIC_USER,
  STAFF_USER,
  ANALYSIS_USER,
  REVIEW_USER,
  DEFAULT_USER}

export class ServerAuthResponse {
  isAuthenticated: boolean;
  errorMessage: string;

  constructor(isAuthenticated: boolean, errorMessage: string) {
    this.isAuthenticated = isAuthenticated;
    this.errorMessage = errorMessage;
  }
}

export class AuthenticatedUser {
  loginName: string;
  loginType: UserType;
  trainee: boolean;
  email: string;
  cellNumber: string;
  readOnly: boolean;
  disabled: boolean;
  clinics: Clinic[];
  accountMaintenancePriv: boolean;
  summaryCSVPriv: boolean;
  weeklyReportPriv: boolean;
  salesReportPriv: boolean;
  invoicePriv: boolean;
  analysisAccessPriv: boolean;
  clinicFilePriv: boolean;
  staffFilePriv: boolean;
  authenticated: boolean;
  errorMessage: string;

  constructor(loginName: string = "", loginType: string = "", email: string = "", cellNumber: string = "",
      readonly: boolean = true, disabled: boolean = true, trainee: boolean = true, clinics: Clinic[] = []) {
    this.loginName = loginName;
    // this.loginType = this.findUserTypeString(loginType);
    this.trainee = trainee;
    this.email = email;
    this.cellNumber = cellNumber;
    this.readOnly = readonly;
    this.disabled = disabled;
    this.clinics = clinics;
    this.accountMaintenancePriv = false;
    this.summaryCSVPriv = false;
    this.weeklyReportPriv = false;
    this.salesReportPriv = false;
    this.invoicePriv = false;
    this.analysisAccessPriv = false;
    this.clinicFilePriv = false;
    this.staffFilePriv = false;
    this.authenticated = false;
    this.errorMessage = "";

    // if (this.loginType === undefined) this.loginType = UserType.DEFAULT_USER;
  }

  setPrivileges(user: AuthenticatedUser) {
    this.accountMaintenancePriv = user.accountMaintenancePriv;
    this.summaryCSVPriv = user.summaryCSVPriv;
    this.weeklyReportPriv = user.weeklyReportPriv;
    this.salesReportPriv = user.salesReportPriv;
    this.invoicePriv = user.invoicePriv;
    this.analysisAccessPriv = user.analysisAccessPriv;
    this.clinicFilePriv = user.clinicFilePriv;
    this.staffFilePriv = user.staffFilePriv;
  }

  // TODO: not used
  // findUserTypeString(userType: string): UserType {
  //   let ttt: UserType;
  //   ttt = userTypeMap[userType];
  //   return ttt;
  // }
}

@inject(NewInstance.of(HttpClient))
export class User {
  private http: HttpClient;
  private authenticated: boolean = false;
  private authenticationChecked: boolean = false;
  private _loginName: string;
  private _loginType: UserType = UserType.DEFAULT_USER;
  private _email: string;
  private _selectedClinic: number = 0;
  private _clinicName: string;
  private _clinicId: string;
  private _clinicNumber: string;
  private _clinicDoctors: string;
  private _doesOHIPFundingApply: boolean;
  private _clinics: Clinic[];
  private _readOnly: boolean;
  private _accountMaintenancePriv: boolean;

  constructor(http: HttpClient) {
    logger.debug("User.constructor:");
    this.http = http.configure((c: HttpClientConfiguration) => {
      c
      .withBaseUrl(window.location.protocol + "//" + window.location.host + environment.userUrl)
      .withDefaults({
        credentials: environment.fetchRequestCredentials,
        headers: {
          "Accept": "application/json",
          "X-Requested-With": "Fetch"}});
    });
    this.clearAuthentication();
    this.clinics = [];
  }


  @computedFrom("_loginName")
  get loginName(): string {
    return this._loginName;
  }

  set loginName(value: string) {
    this._loginName = value;
  }

  @computedFrom("_loginType")
  get loginType(): UserType {
    return this._loginType;
  }

  set loginType(value: UserType) {
    this._loginType = value;
  }

  @computedFrom("_email")
  get email(): string {
    return this._email;
  }

  set email(value: string) {
    this._email = value;
  }

  @computedFrom("_clinics")
  get clinics(): Clinic[] {
    return this._clinics;
  }

  set clinics(value: Clinic[]) {
    if (value && (value.length !== 0)) {
      this._clinics = Object.assign(new Array<Clinic>(), value);
      this._clinicName = this._clinics[this._selectedClinic].clinicName;
      this._clinicId = this._clinics[this._selectedClinic].clinicId;
      this._clinicNumber = this._clinics[this._selectedClinic].clinicNumber;
      this._clinicDoctors = this._clinics[this._selectedClinic].clinicDoctors;
      this._doesOHIPFundingApply = this._clinics[this._selectedClinic].doesOHIPFundingApply;
    } else {
      this._clinics = [];
      this._clinicName = "";
      this._clinicId = "";
      this._clinicNumber = "";
      this._clinicDoctors = "";
      this._doesOHIPFundingApply = false;
    }
  }

  set selectedClinic(value: number) {
    this._selectedClinic = value;
    if (this._clinics.length !== 0) {
      this._clinicName = this._clinics[this._selectedClinic].clinicName;
      this._clinicId = this._clinics[this._selectedClinic].clinicId;
      this._clinicNumber = this._clinics[this._selectedClinic].clinicNumber;
      this._clinicDoctors = this._clinics[this._selectedClinic].clinicDoctors;
      this._doesOHIPFundingApply = this._clinics[this._selectedClinic].doesOHIPFundingApply;
    } else {
      this._clinicName = "";
      this._clinicId = "";
      this._clinicNumber = "";
      this._clinicDoctors = "";
      this._doesOHIPFundingApply = false;
    }
  }

  @computedFrom("_selectedClinic")
  get selectedClinic(): number {
    return this._selectedClinic;
  }

  get clinicId(): string {
    return this._clinicId;
  }

  @computedFrom("_clinicName")
  get clinicName(): string {
    return this._clinicName;
  }

  @computedFrom("_clinicNumber")
  get clinicNumber(): string {
    return this._clinicNumber;
  }

  @computedFrom("_clinicDoctors")
  get clinicDoctors(): string {
    return this._clinicDoctors;
  }

  @computedFrom("_doesOHIPFundingApply")
  get doesOHIPFundingApply(): boolean {
    return this._doesOHIPFundingApply;
  }

  @computedFrom("_readOnly")
  get isReadOnly(): boolean {
    return this._readOnly;
  }

  set isReadOnly(value: boolean) {
    this._readOnly = value;
  }

  hasAccountMaintenancePriv(): boolean {
    return this._accountMaintenancePriv;
  }

  setAccountMaintenancePriv(value: boolean) {
    this._accountMaintenancePriv = value;
  }

  setBaseUrl(url: string) {
    this.http.baseUrl = url + environment.userUrl;
  }

  genHeaders(): {} {
    let headers = new Headers();
    // Have to use literal values for the keys
    headers.append("Content-Type", "application/json");
    headers.append("account-token", sessionStorage.getItem(AccountTokenName)!);
    return headers;
  }


  async isAuthenticated() {
    let response: Response;

    try {
      if (!this.authenticationChecked) {
        response = await this.http.fetch(environment.isAuthenticatedUrl, {
          method: "get",
          headers: { "Content-Type": "application/json" }});

        logger.debug("User.isAuthenticated1:", response.status);
        if (response.ok) {
          let authenticatedUser: AuthenticatedUser;
          authenticatedUser = await response.json();
          // let authenticatedUser: AuthenticatedUser = Object.assign(new AuthenticatedUser(), data);
          logger.debug("User.isAuthenticated2:", authenticatedUser);
          this.authenticated = authenticatedUser.authenticated;
          this.authenticationChecked = true;
          this.loginName = authenticatedUser.loginName;
          this.loginType = authenticatedUser.loginType;
          this.email = authenticatedUser.email;
          this.clinics = authenticatedUser.clinics;
          this.isReadOnly = authenticatedUser.readOnly;
          this._accountMaintenancePriv = authenticatedUser.accountMaintenancePriv;
          return this.authenticated;
        } else {
          let statusText = await response.text();
          if (response.status === UnauthorizedStatusCode) {
            throw new Error(UnauthorizedStatusText);
          } else {
            throw new Error(`${response.status} : ${statusText}`);
          }
        }
      }
      else {
        logger.debug("User.isAuthenticated3:", this.authenticated);
        return this.authenticated;
      }
    }
    catch (reason) {
      logger.debug("User.isAuthenticated4:", reason.message);
      this.clearAuthentication();
      throw reason;
    }
}


  clearAuthentication() {
    logger.debug("User.clearAuthentication:");
    this.authenticated = false;
    this.authenticationChecked = false;
    this.loginType = UserType.DEFAULT_USER;
    this.isReadOnly = true;
  }


  async login(userName: string, password: string, extraAuthCode?: string) {
    let response: Response;
    const cred = {
      userName: userName,
      password: password,
      code: extraAuthCode};

    logger.debug("User.login1:", userName, password, extraAuthCode);
    try {
      response = await this.http.fetch(environment.loginUrl, {
        method: "post",
        body: JSON.stringify(cred),
        headers: { "Content-Type": "application/json" }});

      logger.debug("User.login2:", response.status);

      if (response.ok) {
        let data: any;
        data = await response.json();
        let authenticatedUser: AuthenticatedUser = Object.assign(new AuthenticatedUser(), data[1]);
        sessionStorage.setItem(AccountTokenName, data[0]);
        logger.debug("User.login3:", authenticatedUser, authenticatedUser.loginName, authenticatedUser.errorMessage);
        this.authenticated = authenticatedUser.authenticated;
        this.authenticationChecked = true;
        this.loginName = authenticatedUser.loginName;
        this.loginType = authenticatedUser.loginType;
        this.email = authenticatedUser.email;
        this.clinics = authenticatedUser.clinics;
        this.isReadOnly = authenticatedUser.readOnly;
        this.setAccountMaintenancePriv(authenticatedUser.accountMaintenancePriv);
        return new ServerAuthResponse(this.authenticated, authenticatedUser.errorMessage);
      } else {
        let statusText = await response.text();
        if (response.status === UnauthorizedStatusCode) {
          if ((statusText === ExtraAuthRequiredStatusText) || (statusText === MaximumFailedLoginAttemptsExceededStatusText)) {
            throw new Error(statusText);
          } else {
            throw new Error(UnauthorizedStatusText);
          }
        } else {
          throw new Error(statusText);
        }
      }
    }
    catch (reason) {
      logger.debug("User.login4:", reason.message);
      this.clearAuthentication();
      throw reason;
    }
  }


  async requestCode(userName: string, password: string) {
    let response: Response;
    const cred = {
      userName: userName,
      password: password};

    logger.debug("User.requestCode1:", userName, password);
    try {
      response = await this.http.fetch(environment.requestCodeUrl, {
        method: "post",
        body: JSON.stringify(cred),
        headers: {"Content-Type": "application/json"}});

      logger.debug("User.requestCode2:");
      if (response.ok) {
        let data: boolean;
        data = await response.json();
        return data;
      } else {
        let statusText = await response.text();
        if (response.status === UnauthorizedStatusCode) {
          if ((statusText === ExtraAuthRequiredStatusText) || (statusText === MaximumFailedLoginAttemptsExceededStatusText)) {
            throw new Error(statusText);
          } else {
            throw new Error(UnauthorizedStatusText);
          }
        } else {
          throw new Error(statusText);
        }
      }
    }
    catch (reason) {
      logger.debug("User.requestCode3:", reason.message);
      throw reason;
    }
  }


  async logout() {
    let response: Response;

    logger.debug("User.logout1");
    try {
      response = await this.http.fetch(environment.logoutUrl, {
        method: "get",
        headers: { "Content-Type": "application/json" }});

      if (response.ok) {
        let data: any;
        data = await response.json();
        this.authenticated = false;
        this.authenticationChecked = false;
        this.loginType = UserType.DEFAULT_USER;
        return data;
      } else {
        let statusText = await response.text();
        throw new Error(statusText);
      }
    }
    catch (reason) {
      logger.debug("User.logout2:", reason.message);
      this.authenticated = false;
      this.authenticationChecked = false;
      throw new Error("Communication with the server failed");
    }
  }


  async changeEmail(newEmail: string) {
    let response: Response;
    const data = {
      email: newEmail};

    logger.debug("User.changeEmail:", newEmail);
    try {
    response = await this.http.fetch(environment.changeEmailUrl, {
      method: "post",
      body: JSON.stringify(data),
      headers: this.genHeaders()});

      logger.debug("User.changeEmail2:", response.status);
      if (response.ok) {
        return;
      } else {
        let statusText = await response.text();
        if (response.status === UnauthorizedStatusCode) {
          throw new Error(UnauthorizedStatusText);
        } else if (response.status === BadRequestStatusCode) {
          throw new Error(INVALID_EMAIL_ADDRESS);
        } else {
          throw new Error(`${response.status} : ${statusText}`);
        }
      }
    }
    catch (reason) {
      logger.debug("User.changeEmail3:", reason.message);
      throw reason;
    }
  }


  async changePassword(curPass: string, newPass: string) {
    let response: Response;
    const data = {
      curPass: curPass,
      newPass: newPass};

    logger.debug("User.changePassword1:", curPass, newPass);
    try {
      response = await this.http.fetch(environment.changePasswordUrl, {
        method: "post",
        body: JSON.stringify(data),
        headers: this.genHeaders()});

      logger.debug("User.changePassword2:", response.status);
      if (response.ok) {
        return;
      } else {
        let statusText = await response.text();
        if (response.status === UnauthorizedStatusCode) {
          throw new Error(UnauthorizedStatusText);
        } else if (response.status === BadRequestStatusCode) {
          throw new Error(statusText);
        } else {
          throw new Error(`${response.status} : ${statusText}`);
        }
      }
    }
    catch (reason) {
      logger.debug("User.changePassword3:", reason.message);
      throw reason;
    }
  }
}
